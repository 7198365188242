import { useLinkTo } from "@react-navigation/native";
import React, { memo, useEffect, useRef } from "react"
import { ActivityIndicator, Modal, Platform, ScrollView, StatusBar, Text, TouchableWithoutFeedback, View } from "react-native";
import { TouchableFallback } from "../../components/TouchableFallback";
import { getTimetable, GlobalContext, selectDefaultTimetable, useGlobalStore } from "../../GlobalState";
import { styles } from "../../styles";
import Day from "./day";
import Entry from "./entry";
import { Pager } from "../../components/Pager/PagerUniversal";
import { DayBar, useDayBarState } from './dayBar';
import { EntryModal } from "./EntryModal";

function ViewerScreenImpure({ navigation, route }){
    let initalDay = 0;

    const linkTo = useLinkTo();
    let ModalRef = useRef();
    let PagerRef = useRef();
    let ScrollContainer = useRef();

    let scrollTop = 0;

    let firstSwipe = true;

    let table = useGlobalStore(state=>state.selectedTimetable);
    
    let { type, id, schoolId } = route.params;

    let itemSpacing = styles.viewer.entry.spacing;
    let rowHeight = styles.viewer.row.height;
    let dayBarHeight = styles.viewer.dayBar.height;

    function fixScrollJump(){
        if (Platform.OS == "android"){
            let scrollTop = 73;
            ScrollContainer.current?.scrollToEnd({animated:false});
            PagerRef.current?.setPageWithoutAnimation(1);
            PagerRef.current?.setPageWithoutAnimation(this.initalDay);
            ScrollContainer.current?.scrollTo({x:0,y:scrollTop, animated:false})
        }
    }
    
    useEffect(()=>{
        if (!table){
            getTimetable(schoolId).then(()=>{
                selectDefaultTimetable(schoolId);
            })
        }
    },[]);

    if (!table){

        return (
            <View
                style={{
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
                }}
            >
                <ActivityIndicator size="large" color="#000" />
            </View>
        );
    }
    let _class = table.data.classes.find(e=>e.id == id);
    let filteredDays = table.data.days.filter(e=>e.val != null);

    return (
        <View
            style={{
                flex: 1,
            }}
        >
            <EntryModal ref={ModalRef} />
            <View
                style={{
                    flexDirection: "column",
                    flex: 1,
                    position: "relative",
                }}
            >
                <ScrollView
                    ref={ScrollContainer}
                    style={{
                        paddingTop: StatusBar.currentHeight,
                        flex: 1,
                        overflow: "hidden",
                    }}
                    
                    
                    
                    onScroll={(e)=>{
                        scrollTop = e.nativeEvent.contentOffset.y;
                    }}
                >
                    <View
                        style={{
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <View
                            style={{
                                margin: 10,
                                backgroundColor: "#EDEDED",
                                width: 84.66,
                                borderRadius: 10.33,
                                overflow: "hidden",
                            }}
                        >
                            <TouchableFallback
                                onPress={()=>{
                                    linkTo("/"+schoolId);
                                }}
                            >
                                <View
                                    style={{
                                        paddingVertical: 3.66,
                                        width: "100%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: 16,
                                            color: "#515151"
                                        }}
                                    >{_class.name}</Text>
                                </View>
                            </TouchableFallback>
                        </View>
                    </View>
                    <View
                        style={{
                            flexDirection: "row",
                        }}
                    >
                        <View
                            style={{
                                width: 30.33,
                                marginBottom: dayBarHeight + (StatusBar.currentHeight || 0),
                            }}
                        >
                            { table.data.periods.map(period=>{
                                return (
                                    <View key={period.id}
                                        style={{
                                            marginBottom: itemSpacing,
                                            height: rowHeight,
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                fontSize: 18.38,
                                                color: "#2F2F2F",
                                            }}
                                        >{period.name}</Text>
                                    </View>
                                )
                            }) }
                        </View>
                        <Pager
                            initialPage={initalDay}
                            onMounted={()=>{
                                fixScrollJump();
                            }}
                            
                            onPageSelected={(e)=>{
                                let pos = e.nativeEvent.position;
                                useDayBarState.setState({index:pos});


                                if (firstSwipe){
                                    fixScrollJump();
                                    firstSwipe = false;
                                }
                                
                                
                            }}
                            style={{
                                flex: 1,
                                width: "100%",
                                height: "100%"
                            }}
                            ref={PagerRef}
                        >
                            { filteredDays.map(day=>{
                                return (
                                    <View
                                        key={day.val}
                                        style={{
                                            flex:1,
                                            width: "100%",
                                        }}
                                        collapsable={false}
                                    >
                                        <Day index={day.val} id={id} initalDay={initalDay} ModalRef={ModalRef} />
                                    </View>
                                )
                            }) }
                        </Pager>
                    </View>
                </ScrollView>
                <DayBar PagerRef={PagerRef} />
            </View>
    
        </View>
    );
}

export const ViewerScreen = memo(ViewerScreenImpure);
