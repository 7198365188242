

export let styles = {
    viewer: {
        entry:{
            spacing: 7,
        },
        row: {
            height: 67,
        },
        dayBar:{
            height: 50,
        }
    }
}