import React, { Fragment, useState } from "react";
import { Platform, Pressable, Text, TouchableWithoutFeedback, View } from "react-native";
import { GlobalContext, useGlobalStore } from "../../GlobalState";
import { styles } from "../../styles";

import create from 'zustand';

export const useDayBarState = create((set) => ({
    index: 0,
}));

export function DayBar({ PagerRef }){

    let currentDayIndex = useDayBarState(state=>state.index);

    let table = useGlobalStore(state=>state.selectedTimetable);

    let filteredDays = table.data.days.filter(e=>e.val != null)
    return (<View
        style={{
            flexDirection: "row",
            height: styles.viewer.dayBar.height,
            justifyContent: "center",
            alignItems: "center",
            position: Platform.select({web:"fixed",default: "absolute"}),
            bottom: 0,
            width: "100%",
            backgroundColor: "#fff",
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.30,
            shadowRadius: 4.65,
            elevation: 8
        }}
    >
        {
            filteredDays.map(day=>{
                let active = day.val == currentDayIndex;
                return (
                    <View key={day.id}
                        style={{
                            marginHorizontal: 31.16/2,
                            width: 35.33,
                            height: 35.33,
                        }}
                    >
                        <Pressable
                            onPress={()=>{
                                PagerRef.current.setPageWithoutAnimation(day.val);
                                useDayBarState.setState({index: day.val});
                            }}
                        >
                            <View
                                style={{
                                    width: 30.33,
                                    height: 30.33,
                                    borderRadius: 30.33,
                                    backgroundColor: active ? "#0074D921" : "transparent",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: 15.16,
                                        fontWeight: "bold",
                                        color: active ? "#0074D9" : "#3C3C3C"
                                    }}
                                >{day.shortName}</Text>
                            </View>
                        </Pressable>
                    </View>
                )
            })
        }
    </View>);
}
