import React from "react";
import { Platform } from "react-native";
import { DataRoot, Versions } from "../logic/objects.js";
import { getData, getStorage, getVersions, retrieveData, saveData, setStorage } from "./data";
import * as Network from 'expo-network';

import create from 'zustand';

export const useGlobalStore = create((set) => ({
    schools:{},
    selectedTimetable:null,
}));
window.useGlobalStore = useGlobalStore;

export async function getTimetable(schoolId){
    return getVersions(schoolId).then(versionsJson=>{
        let versions = new Versions(versionsJson);
        console.log(versions);
        useGlobalStore.setState({ schools: { [schoolId]: {
            versions
        } } });
    });
}
export function selectDefaultTimetable(schoolId){
    let state = useGlobalStore.getState();
    let school = state.schools[schoolId];
    let currentId = school.versions.currentId;
    return selectTimetable(schoolId,currentId);
}
export async function selectTimetable(schoolId,ttId){
    return getData(schoolId,ttId).then((json)=>{
        let data = new DataRoot(json);
        let selectedTimetable = {
            id: ttId,
            schoolId,
            data
        };
        console.log(selectedTimetable);
        useGlobalStore.setState({selectedTimetable});
    })
}


/* async function setVersion(id){
    let localData = await retrieveData(id);

    function handleNewData(json){
        let data = new DataRoot(json);
        useGlobalStore.setState({schools: {kdfg: {
            timetableData: data,
            selectedVersionId:id
        }}});
        if (Platform.OS == "web"){
            console.log("data",data);
        } else {
            console.log("data",typeof data);
        }
    }

    if (localData != null){
        handleNewData(localData);
    }
    let online = (await Network.getNetworkStateAsync()).isConnected;
    if (online){
        getData(id).then(json=>{
            handleNewData(json);
            saveData(id,json);
        }).catch(err=>{
            console.warn(err);
            alert("Hiba: "+err.message);
        })
    }
}
async function processVersions(versionsJson){
    let versions = new Versions(versionsJson);
    console.log("versions",versions);
    let id = versions.currentId;
    setVersion(id);
    UpdateGlobalState({
        versions,
    });
}

export async function handleData(){
    let localVersions = await getStorage("versions");
    if (localVersions != null){
        processVersions(localVersions);
    }
    let online = (await Network.getNetworkStateAsync()).isConnected;
    console.log("online",online);
    if (online){
        console.log("Processing versions");
        getVersions().then(async versionsJson=>{
            processVersions(versionsJson);
            setStorage("versions",versionsJson);
        })
    }
}
handleData();
 */