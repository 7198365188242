import React, { Fragment, memo, useEffect, useState } from "react";
import { Platform, Text, View } from "react-native";
import { GlobalContext, useGlobalStore } from "../../GlobalState";
import { styles } from "../../styles";
import { useDayBarState } from "./dayBar";
import Entry from "./entry";

function DayImpure({index, id, ModalRef}){
    let [isVisible, setIsVisible] = useState(false);

    let initalDay = useDayBarState.getState().index;


    if (!isVisible){
        isVisible = index == initalDay;
    }

    useEffect(()=>{
        setTimeout(()=>{
            if (!isVisible){
                setIsVisible(true);
            }
        },1);
    },[]);

    let table = useGlobalStore(state=>state.selectedTimetable);

    if (!isVisible){
        return (<View
            style={{
                flex:1,
                width: "100%",
            }}
        >
            
        </View>);
    }

    let itemSpacing = styles.viewer.entry.spacing;
    let rowHeight = styles.viewer.row.height;
    let dayBarHeight = styles.viewer.dayBar.height;
    let currentDayIndex = index;

    function getEntries(allentries,day,period, classId){
                    
        let entries = allentries.filter(
            e=>e.lesson.classIds.includes(classId) &&
            day.matches(e.days) &&
            e.periods.includes(period.period)
        );

        return entries;
    }

    let day = table.data.days.find(e=>e.val == currentDayIndex);
                    
                    let rows = table.data.periods.map((period,i)=>{
                        let entries = getEntries(table.data.entries,day,period,id);
                        if (entries.length > 0){
                            let division = entries[0].lesson.groups[0].division;
                            let newlist = division.groupIds;
                            entries = newlist.map(groupId=>{
                                let filter = entries.filter(e=>e.lesson.groups.filter(g=>g.id == groupId).length > 0);
                                if (filter.length > 0){
                                    return filter;
                                }
                                return null;
                            }).flat();
                            entries = entries.map(e=>{
                                return {
                                    entry:e,
                                    width: 1,
                                    height: 1,
                                }
                            })
                            /* entries.forEach((e,i)=>{
                                while (e.entry && entries.filter(fl=>fl.entry?.id == e.entry?.id).length > 1){
                                    entries.filter(fl=>fl.entry?.id == e.entry?.id).forEach(e=>{
                                        e.width++;
                                    })
                                    entries.splice(i,1);
                                }
                            }) */
                        }
                        
                        return {
                            period,
                            entries,
                            emptyBefore:0,
                        };
                    });
                    let emptyRows = 0;
                    rows.forEach(row=>{
                        if (row.entries.length == 0){
                            emptyRows++;
                        } else {
                            row.emptyBefore = emptyRows;
                            emptyRows = 0;
                        }
                    });

    

    return (
        <View
                style={{
                    flex:1,
                    width: "100%",
                }}
            >
                <Fragment>
                        { rows.map(({period,entries, emptyBefore}, index)=>{
                            if (entries.length == 0){
                                return null;
                            }
                            return (
                                <View
                                    key={period.id}
                                    style={{
                                        marginTop: emptyBefore * (rowHeight + itemSpacing),
                                        marginBottom: itemSpacing,
                                        marginRight: itemSpacing,
                                        height: rowHeight,
                                        flexDirection: "row",
                                        zIndex: Platform.select({web:100-index, default: undefined}),
                                    }}
                                >
                                    { entries.map(({entry,width,height},i)=>{
                                        return <Entry key={`${entry?.id}-${period?.id}-${i}`} entry={entry} width={width} height={height} period={period} index={i} ModalRef={ModalRef} />;
                                    }) }
                                </View>
                            )
                        }) }
                    </Fragment>
        </View>
    );
}

const Day = memo(DayImpure);
export default Day;